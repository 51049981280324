export default {
    router: {
        dashboard: "dashboard",
        general: {
            general: "general",
            config: "setting",
        },
        auth: {
            auth: "auth",
            admin: "admin",
            role: "role",
            menu: "menu",
        },
    }
}