<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  dayjs.locale('zh-cn');
  export default {
    name: 'App',
    components: {
      
    },
    data() {
      return {
        locale: zhCN
      }
    }
  }

</script>

<style lang="less">
  .ant-menu-submenu-popup,.ant-tooltip {
      display: none !important;
  }
  .overhide {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-form-item {
    margin-bottom: 12px !important;
  }
  .ant-form-item-label > label {
    font-size: 12px !important;
  }
  .ant-form-item-with-help .ant-form-item-explain {
    font-size: 12px;
  }

  *::-webkit-scrollbar {
    display: none;
  }
  .ant-table td { white-space: nowrap; }


  #app{
    height: 100%;
  }

  /** 适配屏幕小的 设备 */
  @media screen and (max-width: 570px) {
      .status-container {
          display: flex;
          justify-content: space-between;
      }
      .full-modal {
          .ant-modal {
              max-width: 100%;
              top: 0;
              padding-bottom: 0;
              margin: 0;
          }
          .ant-modal-content {
              display: flex;
              flex-direction: column;
              min-height: calc(100vh);
          }
          .ant-modal-body {
              flex: 1;
          }
      }
  }
  
</style>
