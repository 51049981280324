<template>
    <div class="header-container">
        <a-layout-header class="header" :style="{ position: 'fixed', zIndex: 1000, width: '100%' }">
            <div class="content">
                <div class="sitename">
                    <span>{{ title }}</span> 
                </div>
                <div class="userinfo">
                    <a-dropdown :trigger="['click']">
                        <div class="lang">
                            {{ langTitle }} <DownOutlined />
                        </div>
                        <template #overlay>
                            <a-menu>
                                <template v-for="item in lang">
                                    <a-menu-item @click="handleLang(item)">
                                        {{ item.title }}
                                    </a-menu-item>
                                </template>
                            </a-menu>
                        </template>
                    </a-dropdown>
                    <div class="username overhide" @click="visible = true">
                        {{ userinfo.nickname }}
                    </div>
                    <div class="logout" title="安全退出" @click="logOut">
                        <poweroff-outlined />
                    </div>
                </div>
            </div>
        </a-layout-header>

        <a-modal v-model:visible="visible" title="个人资料" @ok="handleOk" @cancel="handleCancel" width="100%" wrapClassName="full-modal">
            <a-spin tip="Loading..." :spinning="loading">
                <a-form ref="formData" :rules="formDataRules" layout="horizontal" :model="formData" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-row justify="center">
                        <a-col :xs="24" :lg="8">
                            <a-form-item label="昵称" name="nickname">
                                <a-input v-model:value="formData.nickname" placeholder="请输入昵称" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row justify="center">
                        <a-col :xs="24" :lg="8">
                            <a-form-item label="邮箱" name="email">
                                <a-input v-model:value="formData.email" :disabled="true" placeholder="请输入邮箱" />
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
            </a-spin>
        </a-modal>
    </div>
</template>


<script>
    import { UpdateUser } from '@/api/user';
    export default {
        data() {
            return {
                lang: [
                    {
                        value: "ZhCn",
                        title: "简体中文"
                    },
                    {
                        value: "EnUs",
                        title: "English"
                    }
                ],
                langTitle: localStorage.getItem("lang-title") || "简体中文",
                labelCol: { span: 5 },
                wrapperCol: { span: 14 },
                visible: false,
                loading: false,
                basic: {},
                formDataRules: {
                    nickname : [
                        { required: true, message: "请输入昵称" }
                    ],
                    mobile: [
                        { required: true, message: "请输入手机号" }
                    ],
                    password: [
                        { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'change'},
                    ],
                },
                title: BaseConfig.Sitename,
                userinfo: this.$store.state.userinfo || {},
                formData: JSON.parse(JSON.stringify(this.$store.state.userinfo))
            }
        },
        created() {
            
        },
        methods: {
            handleLang(item) {
                localStorage.setItem("lang",item.value)
                localStorage.setItem("lang-title",item.title)
                location.reload()
            },
            handleCancel() {
                let _this = this
                _this.formData = JSON.parse(JSON.stringify(this.$store.state.userinfo))
            },
            handleOk() {
                let _this = this
                _this.$refs['formData'].validate().then(() => {
                    if(_this.loading) {
                        return
                    }
                    _this.loading = true
                    UpdateUser(_this.formData).then((result) => {
                        _this.loading = false
                        if(result.code == 1) {
                            var message = result.message
                            if(_this.formData.password && _this.formData.password !== "") {
                                message = message + "，正在退出，请重新登入"
                            }
                            _this.$message.success({
                                content: message,
                                onClose() {
                                    if(_this.formData.password && _this.formData.password !== "") {
                                        _this.doLogOut()
                                    }else {
                                        location.reload()
                                    }
                                }
                            });
                        }else {
                            _this.$message.error({
                                duration: 1,
                                content: result.message,
                            });
                        }
                    })
                }).catch((error) => {
                    console.log(error)
                })
            },
            doLogOut() {
                let _this = this
                _this.$store.dispatch("Logout").then((result) => {
                    _this.$message.success({
                        duration: 1,
                        content: result.message,
                        onClose() {
                            _this.$router.push({
                                path: "/login"
                            })
                        }
                    })
                })
            },
            logOut() {
                let _this = this
                _this.$confirm({
                    title: "确定退出当前账号吗？",
                    okText: '确认',
                    cancelText: '取消',
                    onOk() {
                        _this.doLogOut()
                    }
                });
            }
        }
    }
</script>

<style lang="less" scoped>
.header-container {
    user-select: none;
    .ant-layout-header {
        padding: 0 !important;
        height: 70px !important;
        line-height: 70px !important;
    }
    .content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        .sitename {
            height: 100%;
            color: #FFF;
            padding: 0 10px;
            font-size: 16px;
            letter-spacing: 3px;
            font-weight: bold;
        }

        @media screen and (orientation: landscape){
            .sitename {
                width: 256px;
                text-align: center;
            }
        }
        .userinfo {
            color: #FFF;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-weight: bold;
            .username {
                width: 100px;
                text-align: center;
                padding: 0 10px;
                cursor: pointer;
            }
            .logout,.lang {
                height: 100%;
                width: 50px;
                text-align: center;
                cursor: pointer;
                transition-duration: .5s;
            }
            .lang {
                width: auto;
                min-width: 50px;
                padding: 0 10px;
            }
            .logout:hover,.lang:hover,.username:hover {
                background-color: #44505E;
                transition-duration: .5s;
            }
        }
    }
}
</style>

