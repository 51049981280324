import { createRouter, createWebHashHistory} from 'vue-router';
import Layout from '@/layout';

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                component: () => import("@/views/dashboard/index.vue"),
                name: 'dashboard',
                meta: {
                    title: '控制台',
                    menu_type: 2,
                    is_show: 1
                }
            },
            {
                path: '/domain',
                component: () => import("@/views/domain/index.vue"),
                name: 'domain',
                meta: {
                    title: '域名列表',
                    menu_type: 2,
                    is_show: 1
                }
            },
            {
                path: '/domain/host',
                component: () => import("@/views/domain/host/index.vue"),
                name: 'domain/host',
                meta: {
                    title: '域名解析',
                    menu_type: 2,
                    is_show: 0
                }
            },
        ]
    },
    {
        path: '/login',
        component: () => import("@/views/login.vue"),
        name: 'login',
    },
    {
        path: '/register',
        component: () => import("@/views/register.vue"),
        name: 'register',
    },
    {
        path: '/forget',
        component: () => import("@/views/forget.vue"),
        name: 'forget',
    },
    {
        path: '/auto',
        component: () => import("@/views/auto.vue"),
        name: 'auto',
    },
    {
		path: '/404',
        component: () => import("@/views/error/404.vue"),
		name: '404',
	},
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/error/404.vue'),
    },
    {
		path:'/redirect',
		component:Layout,
		hidden:true,
		children:[
			{
				path:'/redirect/:path(.*)',
				component: (resolve) => require(['@/views/redirect'], resolve)
			}
		]
	},
]

export default createRouter({
    history: createWebHashHistory(),
    routes
})