<template>
    <div class="menu-container">
        <a-menu v-model:selectedKeys="selected_keys" v-model:openKeys="open_keys" @openChange="onOpenChange" @click="handleSelectedKeys" mode="inline" :style="{ height: '100%', borderRight: 0 }">
            <span v-for="item in menuData">
                <template v-if="item.meta.menu_type == 2 && item.meta.is_show == 1">
                    <a-menu-item :key="item.name">
                        <component :is="$icons[item.meta.icon]" />
                        <span>
                            {{ item.meta.title }}
                        </span>
                    </a-menu-item>
                </template>
                <template v-else-if="item.meta.menu_type == 1 && item.meta.is_show == 1">
                    <a-sub-menu :key="item.name">
                        <template #title>
                            <span>
                                <component :is="$icons[item.meta.icon]" />
                                <span>{{ item.meta.title }}</span>
                            </span>
                        </template>
                        <template v-if="item.children">
                            <span v-for="itm in item.children">
                                <a-menu-item :key="itm.name" v-if="itm.meta.is_show">
                                    <component :is="$icons[itm.meta.icon]" /><span>{{  itm.meta.title }}</span>
                                </a-menu-item>
                            </span>
                        </template>
                    </a-sub-menu>
                </template>
            </span>
        </a-menu>
    </div>
</template>

<script>
    export default {
        props: {
            menu: {
                type: Array,
                default: []
            },
        },
        data() {
            return {
                menuData: this.menu || [],
                selected_keys: this.$store.state.selected_keys,
                open_keys: this.$store.state.open_keys,
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler(route) {
                    let _this = this
                    let readcrumb = route.matched.map((item) => {
                        return {
                            name: item.name,
                            is_menu: item.meta.is_menu,
                        }
                    })
                    _this.breadcrumbData = readcrumb.slice(1)
                    let router = [];
                    for(let i in _this.breadcrumbData) {
                        let item = _this.breadcrumbData[i]
                        router.push(item.name)
                    }
                    if(router.length > 1) {
                        _this.open_keys = [router[0]]
                        _this.selected_keys = [router[1]]
                    } else {
                        _this.open_keys = []
                        _this.selected_keys = router
                    }
                },
            }
        },
        methods: {
            onOpenChange(openKeys) {
                let _this = this
                if(openKeys && openKeys.length > 0) {
                    _this.open_keys = [openKeys[openKeys.length - 1]]
                }
            },
            handleSelectedKeys(item) {
                let _this = this
                let len = item.keyPath.length
                if(len > 1) {
                    _this.$store.commit("SETOPENKEYS",[item.keyPath[0]])
                    _this.$store.commit("SETSELECTEDKEYS",[item.keyPath[1]])
                } else {
                    _this.open_keys = []
                    _this.$store.commit("SETOPENKEYS",[])
                    _this.$store.commit("SETSELECTEDKEYS",item.keyPath)
                }
                _this.$router.push("/" + item.key)
                _this.$emit("change")
            },
        }
    }
</script>

<style lang="less" scoped>

</style>