export default {
    router: {
        dashboard: "控制台",
        general: {
            general: "常规管理",
            config: "系统配置",
        },
        auth: {
            auth: "权限管理",
            admin: "管理员",
            role: "角色管理",
            menu: "菜单管理",
        },
    }
}