
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { setToken } from '@/tool/common'

import { Login,GetUserInfo,getRoutes,Logout } from '@/api/user';

import Layout from '@/layout';
import routes from '@/router/index'
const btnAuth = []
const store = createStore({
    state () {  // 全局状态
        return {
            selected_keys: [],
            open_keys: [],
            menus: [],
            btn_auth: [], // 用户权限
            // 用户信息
            userinfo: {}
        }
    },
    mutations: {    //  同步修改数据
        SETSELECTEDKEYS(state,value) {
            state.selected_keys = value || ["dashboard"]
        },
        SETOPENKEYS(state,value) {
            state.open_keys = value || []
        },
        SETMENU(state,value) {
            state.menus = value
        },
        SETBTNAUTH(state,value) {
            state.btn_auth = value || []
        },
        SETUSERINFO(state,value) {
            state.userinfo = value || {}
        },
    },
    actions: {  // 异步修改数据
        GenerateRoutes({ commit }) {
            return new Promise( resolve => {
                var rr = routes.options.routes[0].children
                const trendsRoute = filterAsyncRouter(rr)
                commit("SETMENU",trendsRoute)
                commit("SETBTNAUTH",btnAuth)
                resolve(trendsRoute)
            })
        },
        GetUserInfo({ commit, state }) {
            return new Promise( resolve => {
                if (!Object.keys(state.userinfo).length) {
                    GetUserInfo().then(result => {
                        if(result) {
                            commit("SETUSERINFO",result.data)
                            resolve(result.data)
                        }
                    })
                }else {
                    resolve(state.userinfo)
                }
            })
        },
        Login({ commit },UserInfo) {
            return new Promise((resolve,reject)=>{
				// 请求登入接口 返回token
                Login(UserInfo).then( result => {
					if(result.code == 1) {
                        setToken(result.data.token)
					}
                    resolve(result)
				}).catch(error=>{
					reject(error)
				})
			})
        },
        Logout({ commit }) {
            return new Promise((resolve,reject)=>{
                setToken("")
                localStorage.setItem("vuex","")
                commit("SETMENU",[])
                commit("SETBTNAUTH",[])
                commit("SETUSERINFO",{})
                resolve({code: 1,message:"退出成功",data: []})
			})
        },
        AutoLogin({ commit }, token) {
            setToken(token)
            commit("SETUSERINFO",{})
        }
    },
    getters: {

    },
    modules: {

    },
    plugins: [
        createPersistedState({
            reducer: (state) => {
                return {
                    selected_keys: state.selected_keys,
                    open_keys: state.open_keys,
                    btn_auth: state.btn_auth,
                }
            }
        })
    ]
})

function filterAsyncRouter(router) {
    return router.filter(item => {
        if(item.auth) {
            btnAuth.push(item.auth)
        }
        if(item.component) {
            if(item.component === 'Layout') {
                item.component = Layout
            }else {
                item.component = loadView(item.component)
            }
        }
        if (item.children != null && item.children && item.children.length) {
            item.children = filterAsyncRouter(item.children)
        }
        return true
    })
}

export const loadView = (view) => { // 路由懒加载
    return () => require.ensure([], (require) => require(`@/views/${view}`))
}

export default store