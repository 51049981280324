import request from './request'
import router from '@/router'
import store from '@/store'

export function hasPermission(perms) {
  let hasPermission = false
  let permissions = store.state.btn_auth
  if(permissions[0] === "*"){
     hasPermission = true;
  }
  for(let i = 0, len = permissions.length; i < len; i++) {
    if(permissions[i] === perms) {
      hasPermission = true;
      break
    }
  }
  return hasPermission
}


export function requests(method = 'get', e = {url: '' , data: {} , header: {}}) {
  let obj = {}
  let url = "/" + e.url
  let params = e.data
  if(method == "get") {
      url = url + enableUrl(params)
      params = {}
  }
  obj = {
    method: method,
    url: url,
    data: params
  }
  return request(obj)
}

function enableUrl(data){
	let url = "";
  if(typeof(data) === "object"){
    let j = 0;
    for( let i in data){
        if(j == 0){
          url += "?"
        }else{
          url += "&"
        }
        url += i+"="+data[i]
        j++
    }
  }
  return decodeURI(url);
}


/**
 * 跳转
 * @param {*} e={url,data,hreftype}
 * @param {*} dotype 跳转传参类型
 */
export const openPage = function(e) {
  if (!e.hasOwnProperty('url')) {
		console.log('缺少跳转url')
		return
	}
  if(e.url && typeof e.url === 'number' && !isNaN(e.url)) {
		e.hreftype = 'navigateBack';
	}
  switch (e.hreftype) {
    case 'navigateBack':
      this.$router.go(e.url)
      break;
    default: //navigateTo
      router.push({
        path: e.url,
        query: e.data
      })
  }
}

