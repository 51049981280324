import router from '@/router'

import store from '@/store'
import { getToken } from '@/tool/common'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
	showSpinner: true
})

const whiteList = ['/login', '/404','/register','/forget',"/auto"]

router.beforeEach((to, from, next) => {
    NProgress.start()
    let token = getToken()
    if(token) {
        if (to.path === '/login') {
            next({ path: '/' })
            NProgress.done()
        }else {
            store.dispatch("GetUserInfo").then((userinfo) => {
                if(store.state.menus.length === 0) {
                    store.dispatch('GenerateRoutes').then(Routes => {
                        
                        next({ ...to, replace: true })
                    })
                } else {
                    next()
                }
            })
        }
    }else {
        if (whiteList.indexOf(to.path) !== -1) {
			next()
		} else {
			next(`/login?redirect=${to.fullPath}`)
			NProgress.done();
		}
    }
})

router.afterEach(() => {
	NProgress.done()
})