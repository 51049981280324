

import { requests } from '@/tool/util'

export function Login(formData) {
    return requests("post",{
        url: "index/login",
        data: formData
    })
}

export function Register(formData) {
    return requests("post",{
        url: "index/register",
        data: formData
    })
}

export function ChangePassword(formData) {
    return requests("post",{
        url: "index/ChangePassword",
        data: formData
    })
}


export function RegisterVCode(formData) {
    return requests("get",{
        url: "index/RegisterVCode",
        data: formData
    })
}

export function VEmail(formData) {
    return requests("get",{
        url: "index/VEmail",
        data: formData
    })
}


export function ForgetVCode(formData) {
    return requests("get",{
        url: "index/ForgetVCode",
        data: formData
    })
}





export function Logout() {
    return requests("post",{
        url: "index/logOut",
    })
}

export function GetUserInfo() {
    return requests("get",{
        url: "index/userinfo",
    })
}
export function getRoutes() {
    return requests("get",{
        url: "index/menus",
    })
}

export function GetBalanceLogs(formData) {
    return requests("get",{
        url: "UserBalanceLogs/getBalanceLogs",
        data: formData
    })
}



// 用户列表接口
export function UpdateUser(formData = {}) {
    return requests("put",{
        url: `user/update`,
        data: formData
    })
}

